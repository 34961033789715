import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 56,
    "& > *": {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      width: "100%",
    },
  },
  ul: {
    listStyle: "none",
    margin: 0,
    padding: theme.spacing(1),
  },
  li: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: "#f06292",
    paddingRight: theme.spacing(1),
  },
}));

const steps = [
  { label: "Meet Wendy", checked: true },
  { label: "Sign Notice of Intended Marriage", checked: true },
  { label: "Deposit paid", checked: true },
  { label: "Draft of ceremony", checked: true },
  { label: "Select vows and ring exchange", checked: true },
  { label: "Select readings", checked: true },
  { label: "Select music", checked: true },
  { label: "Sign Statutory Declaration", checked: true },
  { label: "Final payment to Wendy", checked: true },
  { label: "Marriage registered", checked: false },
];

export default function SimplePaper() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h1" gutterBottom>
        Your checklist
      </Typography>

      <Paper>
        <ul className={classes.ul}>
          {steps.map((step, index) => {
            return (
              <li className={classes.li} key={index}>
                {step.checked ? (
                  <CheckCircleIcon className={classes.icon} />
                ) : (
                  <RadioButtonUncheckedIcon className={classes.icon} />
                )}
                {step.label}
              </li>
            );
          })}
        </ul>
      </Paper>
    </div>
  );
}
