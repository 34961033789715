import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  onSnapshot,
  collection,
  doc,
  getDoc,
  setDoc,
  query,
  orderBy,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import * as Sentry from "@sentry/browser";
import Spinner from "./components/Spinner";
import App from "./App";
import Login from "./screens/Login";

function sendTravelUpdate(firebaseApp, weddingId, mins, callback) {
  const currentTime = new Date();
  const eta = new Date(currentTime.valueOf() + mins * 60 * 1000);

  const db = getFirestore(firebaseApp);
  const newUpdateRef = doc(collection(db, "weddings", weddingId, "updates"));
  setDoc(newUpdateRef, {
    type: "travel_update",
    eta: eta,
    createdAt: currentTime,
  })
    .then(() => {
      console.debug(`**** add 'travel_update' success`);
      callback();
    })
    .catch((e) => {
      Sentry.captureException(e);
    });

  const sendSms = httpsCallable(getFunctions(), "sendSms");
  sendSms({ message: `⏱  The bride is expected in ${mins} minutes.` });
}

function readyToSayIDo(firebaseApp, weddingId, callback) {
  const currentTime = new Date();
  const db = getFirestore(firebaseApp);
  const newUpdateRef = doc(collection(db, "weddings", weddingId, "updates"));
  setDoc(newUpdateRef, {
    type: "ready_to_start",
    createdAt: currentTime,
  })
    .then(() => {
      console.debug(`**** add 'ready_to_start' success`);
      callback();
    })
    .catch((e) => {
      Sentry.captureException(e);
    });

  const sendSms = httpsCallable(getFunctions(), "sendSms");
  sendSms({ message: `💖 The bride is ready to walk down the aisle!` });
}

async function lookupWeddingId(firebaseApp, userId) {
  const db = getFirestore(firebaseApp);
  const userSnapshot = await getDoc(doc(db, "users", userId));
  return userSnapshot.data().wedding;
}

export function FirebaseApp({ firebaseApp }) {
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [weddingId, setWeddingId] = useState();
  const [wedding, setWedding] = useState({ coupleNames: "fred" });
  const [user, setUser] = useState();

  useEffect(() => {
    const auth = getAuth();
    console.debug("** Subscribe to onAuthStateChanged");
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.debug(`**** onAuthStateChanged:`, user);
      if (user) {
        setWeddingId(await lookupWeddingId(firebaseApp, user.uid));
      }
      setLoadingAuth(false);
      setUser(user);
    });
    return () => {
      console.debug("** Unsubscribe to onAuthStateChanged");
      unsubscribe();
    };
  }, [firebaseApp]);

  useEffect(() => {
    if (!weddingId) {
      return;
    }
    const db = getFirestore(firebaseApp);

    const listenForWeddingChanges = () => {
      console.debug("** Start listenForWeddingChanges");
      const unsubscribe = onSnapshot(
        doc(db, "weddings", weddingId),
        (updatedDoc) => {
          const source = updatedDoc.metadata.hasPendingWrites
            ? "Local"
            : "Server";
          console.debug(`**** onSnapshot [${source}]: `, updatedDoc.data());
          setWedding(updatedDoc.data());
          setLoadingData(false);
        }
      );
      return () => {
        console.debug("** Stop listenForWeddingChanges");
        unsubscribe();
      };
    };
    return listenForWeddingChanges();
  }, [firebaseApp, weddingId]);

  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    if (!weddingId) {
      return;
    }
    const db = getFirestore(firebaseApp);

    const listenForWeddingUpdateChanges = () => {
      console.debug("** Start listenForWeddingUpdateChanges");
      const q = query(
        collection(db, "weddings", weddingId, "updates"),
        orderBy("createdAt", "desc")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const updates = [];
        querySnapshot.forEach((doc) => {
          const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          const docData = doc.data();
          console.debug(`**** onSnapshot [${source}]: `, docData);
          const update = {
            type: docData.type,
            createdAt: docData.createdAt.toDate(),
          };
          if (docData.eta) {
            update.eta = docData.eta.toDate();
          }
          updates.push(update);
        });
        console.debug(`**** onSnapshot updates: `, updates);
        setUpdates(updates);
      });

      return () => {
        console.debug("** Stop listenForWeddingUpdateChanges");
        unsubscribe();
      };
    };
    return listenForWeddingUpdateChanges();
  }, [firebaseApp, weddingId]);

  if (loadingAuth) {
    return <Spinner />;
  } else if (!loadingAuth && !user) {
    return <Login onSuccessfulAuth={(user) => setUser(user)} />;
  } else if (loadingData) {
    return <Spinner />;
  } else {
    return (
      <App
        wedding={wedding}
        updates={updates}
        sendTravelUpdate={(mins, callback) =>
          sendTravelUpdate(firebaseApp, weddingId, mins, callback)
        }
        readyToSayIDo={(callback) =>
          readyToSayIDo(firebaseApp, weddingId, callback)
        }
        onSuccessfulSignOut={() => {
          setWeddingId(null);
        }}
      />
    );
  }
}
