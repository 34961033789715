import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
});

export default function Navigation() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [value, setValue] = React.useState(currentRoute);
  useEffect(() => {
    if (value !== currentRoute) {
      setValue(currentRoute);
    }
  }, [value, currentRoute]);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        history.push(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction label="Home" value="/" icon={<HomeIcon />} />
      <BottomNavigationAction label="Checklist" value="/checklist" icon={<CheckCircleIcon />} />
      <BottomNavigationAction label="The big day" value="/wedding-day" icon={<FavoriteIcon />} />
    </BottomNavigation>
  );
}
