import React from "react";
import { getAuth, signOut } from "firebase/auth";
import { useHistory } from "react-router-dom";

function signOutUser(history, onSuccessfulSignOut) {
  console.debug("** signOutUser");
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      console.log(`>> Signed out sucessfully`);
      onSuccessfulSignOut();
      history.push("/");
    })
    .catch((error) => {
      console.error(`>> Error`, error);
    });
}

export default function SignOutScreen({ onSuccessfulSignOut }) {
  const history = useHistory();
  return (
    <>
      <h3>
        Sign out of the app. You'll need to log in again to see wedding details.
      </h3>
      <button onClick={() => signOutUser(history, onSuccessfulSignOut)}>
        Sign out
      </button>
    </>
  );
}
