import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import EventIcon from "@material-ui/icons/Event";
import NextTodoCard from "./NextTodoCard";
import VenueCard from "./VenueCard";
import WeddingDayHomeCard from "./WeddingDayHomeCard";
import confetti from "canvas-confetti";
import { randomInRange } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 56,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  heading: {
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  paper: {
    padding: theme.spacing(1),
    width: "100%",
  },
  calendarIcon: {
    position: "relative",
    top: theme.spacing(0.75),
  },
}));

export default function HomeScreen({ wedding, weddingDate, isWeddingDay }) {
  console.log("=> Rendering HomeScreen");
  const classes = useStyles();
  const daysLeft = Math.round(
    (weddingDate - new Date()) / (1000 * 60 * 60 * 24)
  );
  const displayWeddingDate = weddingDate.toDateString();

  useEffect(() => {
    if (isWeddingDay) {
      for (let i = 1; i < 5; i++) {
        setTimeout(() => {
          confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: randomInRange(50, 100),
            origin: {
              x: randomInRange(0.2, 0.8),
              y: randomInRange(0.3, 0.7),
            },
          });
        }, 1100 * i);
      }
    }
  });

  return (
    <div className={classes.root}>
      <Typography variant="h5" component="h1" className={classes.heading}>
        Hi {wedding.coupleNames}
      </Typography>

      {!isWeddingDay && (
        <>
          <NextTodoCard nextToDo={wedding.nextToDo} />

          <Paper className={classes.paper}>
            <Typography variant="h4" component="h2" gutterBottom>
              <EventIcon
                fontSize="inherit"
                color="action"
                className={classes.calendarIcon}
              />{" "}
              {displayWeddingDate}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Your special day is only <strong>{daysLeft}</strong> days away!
            </Typography>
          </Paper>
        </>
      )}

      {isWeddingDay && <WeddingDayHomeCard />}

      <VenueCard venue={wedding.venue} />
    </div>
  );
}
