import React from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 56,
    "& > *": {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      width: "100%",
    },
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
}));

export default function SimplePaper({ onSuccessfulAuth }) {
  const classes = useStyles();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const signInUser = () => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        setError(false);
        onSuccessfulAuth(user);
      })
      .catch((error) => {
        setError(true);
        logEvent(getAnalytics(), "login_failed");
      });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h1" gutterBottom>
        Log in
      </Typography>

      <Paper>
        {error && (
          <Alert severity="error">
            Sorry, check your email and password and try again
          </Alert>
        )}
        <TextField
          id="email"
          label="Email"
          onChange={handleEmailChange}
          fullWidth
          error={error}
        />
        <TextField
          id="filled-basic"
          label="Password"
          onChange={handlePasswordChange}
          fullWidth
          error={error}
        />
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={signInUser}
          className={classes.buttons}
          fullWidth
        >
          Log in
        </Button>
      </Paper>
    </div>
  );
}
