import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ShareIcon from "@material-ui/icons/Share";
import MapIcon from "@material-ui/icons/Map";

const useStyles = makeStyles((theme) => ({
  root: {},
  cardActions: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  rightAction: {
    marginLeft: "auto",
  },
}));

export default function VenueCard({ venue }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        component="img"
        alt="Image of venue"
        height="140"
        src={venue.imageUrl}
        title="Image of venue"
      />
      <CardContent>
        <Typography variant="h4" component="h2">
          {venue.name}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions} disableSpacing>
        <Button size="small" color="primary" startIcon={<ShareIcon />}>
          Share
        </Button>
        <Button
          size="small"
          color="primary"
          href={venue.mapUrl}
          startIcon={<MapIcon />}
          className={classes.rightAction}
        >
          View in Maps
        </Button>
      </CardActions>
    </Card>
  );
}
