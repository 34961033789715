import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import HomeScreen from "./screens/home";
import YourChecklist from "./screens/YourChecklist";
import WeddingDay from "./screens/WeddingDay";
import Notifications from "./screens/Notifications";
import SignOut from "./screens/SignOut";
import Navigation from "./components/Navigation";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import logo from "./logo.png";
import "./App.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#f06292",
    },
    secondary: {
      main: "#f48fb1",
    },
  },
});

const useStyles = makeStyles({
  appTitle: {
    flexGrow: 1,
  },
});

function PageViewLogger() {
  const location = useLocation();
  const analytics = getAnalytics();

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  useEffect(() => {
    console.log(`==> analytics: page_view: ${location.pathname}`);
    logEvent(analytics, "page_view", { path_name: location.pathname });
  }, [analytics, location.pathname]);
  return null;
}

const isTodayTheDay = (date) =>
  date.toDateString() === new Date().toDateString();

function App({
  wedding,
  updates,
  sendTravelUpdate,
  readyToSayIDo,
  onSuccessfulSignOut,
}) {
  console.log("=> Rendering App");

  const [adminMode, setAdminMode] = useState(false);

  // This is needed of the Admin "change the date" style testing. I found that
  // if I didn't add the effect, that downstream components wouldn't see the
  // state change.
  const [weddingDate, setWeddingDate] = useState(wedding.date.toDate());
  useEffect(() => {
    const date = wedding.date.toDate();
    setWeddingDate(date);
    setIsWeddingDay(isTodayTheDay(date));
  }, [wedding.date]);
  const [isWeddingDay, setIsWeddingDay] = useState(isTodayTheDay(weddingDate));

  const handleDateChange = (date) => {
    setAdminMode(false);
    setWeddingDate(date);
    setIsWeddingDay(isTodayTheDay(date));
  };

  const classes = useStyles();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className="App">
          <AppBar position="static">
            <Toolbar>
              <img src={logo} alt="Wendy O'brien logo" className="logo" />
              <Typography variant="h6" className={classes.appTitle}>
                Say I Do
              </Typography>
              <IconButton color="inherit" component={Link} to="/updates">
                <NotificationsActiveIcon />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={() => setAdminMode(!adminMode)}
              >
                <EventAvailableIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          {adminMode && (
            <>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  disablePast
                  showTodayButton
                  variant="dialog"
                  value={weddingDate}
                  label={null}
                  onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </>
          )}
          <Switch>
            <Route path="/checklist">
              <YourChecklist />
            </Route>
            <Route path="/wedding-day">
              <WeddingDay
                isWeddingDay={isWeddingDay}
                sendTravelUpdate={sendTravelUpdate}
                readyToSayIDo={readyToSayIDo}
              />
            </Route>
            <Route path="/updates">
              <Notifications updates={updates} />
            </Route>
            <Route path="/signout">
              <SignOut onSuccessfulSignOut={onSuccessfulSignOut} />
            </Route>
            <Route path="/">
              <HomeScreen
                wedding={wedding}
                weddingDate={weddingDate}
                isWeddingDay={isWeddingDay}
              />
            </Route>
          </Switch>
          <Navigation />
        </div>
      </ThemeProvider>
      <PageViewLogger />
    </Router>
  );
}

export default App;
