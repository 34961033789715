import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    position: "relative",
    top: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  cardActions: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  rightAction: {
    marginLeft: "auto",
  },
}));

export default function NextTodoCard({ nextToDo }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          <CheckCircleOutlineIcon
            fontSize="inherit"
            color="action"
            className={classes.icon}
          />
          Next thing to do...
        </Typography>
        <Typography gutterBottom variant="h4" component="p">
          {nextToDo}
        </Typography>
        <Typography variant="body1">
          Please feel free to contact me if you have any questions.
        </Typography>
      </CardContent>
      <CardActions disableSpacing className={classes.cardActions}>
        <Button
          size="small"
          color="primary"
          href="mailto:idoweddings@iinet.net.au"
          startIcon={<EmailIcon />}
        >
          Email Wendy
        </Button>
        <Button
          size="small"
          color="primary"
          href="tel:0438332741"
          startIcon={<PhoneIcon />}
          className={classes.rightAction}
        >
          Call Wendy
        </Button>
      </CardActions>
    </Card>
  );
}
