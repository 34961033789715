import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 56,
    "& > *": {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      width: "100%",
    },
  },
  headings: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
  icons: {
    marginRight: theme.spacing(1),
  },
}));

export default function WeddingDay({
  isWeddingDay,
  sendTravelUpdate,
  readyToSayIDo,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = (mins) => {
    sendTravelUpdate(mins, () => {
      setOpen(true);
    });
  };

  const sayIDo = () => {
    readyToSayIDo(() => {
      setOpen(true);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1" gutterBottom>
        The big day
      </Typography>

      <Paper>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.headings}
        >
          <DirectionsCarIcon /> Leaving for ceremony
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.buttons}>
          Press the button that's closest to your estimated travel time.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.buttons}>
          You can keep sending updates as the situation changes.
        </Typography>

        <ButtonGroup
          color="primary"
          variant="contained"
          size="large"
          className={classes.buttons}
          aria-label="Estimated time to ceremony"
        >
          <Button onClick={() => handleClick(15)} disabled={!isWeddingDay}>
            15 mins
          </Button>
          <Button onClick={() => handleClick(30)} disabled={!isWeddingDay}>
            30 mins
          </Button>
          <Button onClick={() => handleClick(60)} disabled={!isWeddingDay}>
            60 mins
          </Button>
          <Button onClick={() => handleClick(90)} disabled={!isWeddingDay}>
            90 mins
          </Button>
        </ButtonGroup>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            We've sent Wendy your update.
          </Alert>
        </Snackbar>
      </Paper>

      <Paper>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.headings}
        >
          <FavoriteBorderIcon /> Ready to say "I do"!
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.buttons}>
          Press this button to let Wendy know{" "}
          <strong>you’re ready to walk down the aisle.</strong>
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttons}
          disabled={!isWeddingDay}
          onClick={sayIDo}
          fullWidth
        >
          <FavoriteIcon className={classes.icons} /> Say I do!
        </Button>
      </Paper>
    </div>
  );
}
