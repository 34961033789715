import React from "react";
import ReactDOM from "react-dom";
import { initializeApp } from "firebase/app";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import { FirebaseApp } from "./FirebaseApp";
import * as serviceWorker from "./serviceWorker";

const firebaseConfig = {
  apiKey: "AIzaSyDwH_fgzYX8bIY9jpZKWMIEYVQW8F3ezHw",
  authDomain: "celebrant-assistant.firebaseapp.com",
  databaseURL: "https://celebrant-assistant.firebaseio.com",
  projectId: "celebrant-assistant",
  storageBucket: "celebrant-assistant.appspot.com",
  messagingSenderId: "567496599054",
  appId: "1:567496599054:web:5f9a96fa169e19b470be5f",
  measurementId: "G-N76T4BWVVG",
};

Sentry.init({
  dsn: "https://1b9921d52f0c4609b9d51f5c4215303c@o516438.ingest.sentry.io/5622865",
  integrations: [new Integrations.BrowserTracing()],
  denyUrls: [/localhost/i],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const firebaseApp = initializeApp(firebaseConfig);

ReactDOM.render(
  <FirebaseApp firebaseApp={firebaseApp} />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
