import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { differenceInMinutes } from "date-fns";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import FavoriteIcon from "@material-ui/icons/Favorite";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 56,
    "& > *": {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      width: "100%",
    },
  },
  heading: {
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  paper: {
    margin: theme.spacing(1),
  },
  alerts: {
    padding: theme.spacing(1),
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  latestUpdate: {
    border: `3px solid ${theme.palette.info.main}`,
  },
  oldUpdate: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[200],
  },
  readyToStartUpdate: {
    border: `3px solid ${theme.palette.success.main}`,
  },
  readyToStartUpdateIcon: {
    color: theme.palette.primary.main,
  },
}));

export default function NotificationsScreen({ updates }) {
  const classes = useStyles();

  const currentUpdatesData = updates;

  const dateInTheFuture = (update) =>
    update.type === "ready_to_start" ||
    differenceInMinutes(update.eta, new Date()) > 0;

  const updatesUI = currentUpdatesData
    .filter(dateInTheFuture)
    .map((update, index) => {
      if (update.type === "ready_to_start") {
        return (
          <Alert
            severity="success"
            key={index}
            className={classes.readyToStartUpdate}
            icon={
              <FavoriteIcon
                fontSize="inherit"
                className={classes.readyToStartUpdateIcon}
              />
            }
          >
            <AlertTitle>
              <strong>The bride is ready to walk down the aisle!</strong>
            </AlertTitle>
          </Alert>
        );
      } else {
        let className = index === 0 ? classes.latestUpdate : classes.oldUpdate;

        return (
          <Alert severity="info" key={index} className={className}>
            <AlertTitle>
              The bride is expected in{" "}
              {differenceInMinutes(update.eta, new Date())} minutes.
            </AlertTitle>
          </Alert>
        );
      }
    });
  const latestUpdate = updatesUI.shift();
  return (
    <>
      {!latestUpdate && !updatesUI.length && (
        <Typography variant="h5" component="h1" className={classes.heading}>
          Waiting for first update from bridal party
        </Typography>
      )}

      {latestUpdate && (
        <>
          <Typography variant="h5" component="h1" className={classes.heading}>
            Latest bridal party update
          </Typography>

          <Paper className={classes.paper}>
            <div className={classes.alerts}>{latestUpdate}</div>
          </Paper>
        </>
      )}

      {updatesUI.length > 0 && (
        <>
          <Typography variant="h5" component="h1" className={classes.heading}>
            Previous updates
          </Typography>
          <Paper className={classes.paper}>
            <div className={classes.alerts}>{updatesUI}</div>
          </Paper>
        </>
      )}
    </>
  );
}
