import * as Sentry from "@sentry/browser";

export function storeInLocalStorage(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(`Error updating ${key} in localStorage`, e);
    Sentry.captureException(e);
  }
}

export function loadFromLocalStorage(key, defaultValue) {
  try {
    return JSON.parse(localStorage.getItem(key)) || defaultValue;
  } catch (e) {
    console.error(`Error loading ${key} from localStorage`, e);
    return defaultValue;
  }
}

export function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

