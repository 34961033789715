import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    width: "100%",
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
  icons: {
    marginRight: theme.spacing(1),
  },
}));

export default function WeddingDayHomeCard() {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography variant="h4" component="h2" gutterBottom>
        It's the big day!{" "}
        <span role="img" aria-label="tada">
          🎉
        </span>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Congrats! You can send Wendy updates on your estimated arrival time!
      </Typography>
      <Button
        size="large"
        variant="contained"
        color="primary"
        className={classes.buttons}
        component={Link}
        to="/wedding-day"
        fullWidth
      >
        <NotificationsActiveIcon className={classes.icons} /> Send Wendy
        updates!
      </Button>
    </Paper>
  );
}
