import React from "react";
import heartSpinner from "./hearts.svg";

export default function Spinner() {
  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "#f06292",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={heartSpinner} alt="loading" />
    </div>
  );
}
